<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="jobs">
        <div class="man-title">
          岗位管理
          <el-button @click="add(0)" class="btn" type="primary" size="mini" :disabled="roleId == 2 || roleId == 3 || roleId == 4">添加岗位</el-button>
        </div>
        <div class="table-tree">
          <div class="tree">
            <div class="tree-title"><i class="el-icon-share"></i> 岗位结构</div>
            <el-tree
              @node-click="treeRowClick"
              :data="treeData"
              :props="defaultProps"
              default-expand-all
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span style="margin-right: 40px">
                  <i class="el-icon-folder-opened"></i>
                  {{ node.label }}
                </span>
                <el-dropdown placement="bottom-start" trigger="click">
                  <span @click.stop>
                    <span class="el-dropdown-link">
                      <i class="el-icon-more-outline operate"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4">
                      <div @click="add(data.id)">添加下属岗位</div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4">
                      <div @click="edit(data)">修改</div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4">
                      <div @click="dlt(data.id)">删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableData"  style="width: 100%">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="name" label="姓名"></el-table-column>
              <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">
                  <span v-if="scope.row.sex == 1">男</span>
                  <span v-else>女</span>
                </template>
              </el-table-column>
              <el-table-column prop="departmentName" label="部门"></el-table-column>
              <el-table-column prop="name" label="岗位">
                <template slot-scope="scope">
                  <div
                    v-for="item in scope.row.positionInfoList"
                    :key="item.positionId"
                  >{{item.positionName}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="电话"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {},
      dialogTitle: "",
      dialogVisible: false,
      tableData: [],
      treeData: [],
      department: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      departmentTreeData: [],
      roleId: 0
    };
  },
  methods: {
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    loadTableData(id) {
      this.loading = true
      this.$ajax.post('queryFilter', {
        positionId: id ? id : 0
      }).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    edit(row) {
      this.$router.push({ name: 'jobsEdit', params: row})
    },
    dlt(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("positionDelete", {
          id: id,
        }).then((res) => {
          this.loadList();
        });
      });
    },
    ok() {
      if (this.dialogTitle != "编辑") {
        this.$ajax.post("positionCreate", this.form).then((res) => {
          this.dialogVisible = false;
          this.loadList();
        });
      } else {
        this.$ajax.post("positionUpdate", this.form).then((res) => {
          this.dialogVisible = false;
          this.loadList();
        });
      }
    },
    add(parentId) {
      this.$router.push({ name: 'jobsAdd', params: {
        parentId: parentId
      }})
    },
    loadList() {
      this.$ajax.get("positionQueryAll").then((res) => {
        this.treeData = res.data;
      });
    },
    loadDepartmentList() {
      this.$ajax.get("departmentTree").then((res) => {
        this.getTreeData(res.data);
        this.departmentTreeData = res.data;
      });
    },
    getTreeData(data) {
      this.department = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            this.department.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
    },
  },
  mounted() {
    this.loadDepartmentList();
    this.loadTableData()
    this.loadList();
    this.roleId = sessionStorage.getItem('r')
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.jobs {
  height: 100%;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409eff;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.btn {
  float: right;
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
</style>

<style lang="less">
.el-tree-node__content {
  height: 40px;
}
.el-dropdown-menu__item {
  padding: 0;
  > div {
    padding: 0 20px;
  }
}
</style>